import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/MathClub/2023/mathCCA/1.jpg';
// import p2 from 'assests/Photos/Clubs/MathClub/2023/mathCCA/2.jpg';
// import p3 from 'assests/Photos/Clubs/MathClub/2023/mathCCA/3.jpg';
// import p4 from 'assests/Photos/Clubs/MathClub/2023/mathCCA/4.jpg';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import { SidebarMath } from 'views/MathClub2023/components';


const MathCCA2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
      fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
        .then(response => response.json())
        .then(data => setConfig(data))
        .catch(error => console.error('Error fetching config:', error));
    }, []);
    
    const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/MathClub/mathCCA/1.webp`;
    const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/MathClub/mathCCA/2.webp`;
    const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/MathClub/mathCCA/3.webp`;
    const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/association/MathClub/mathCCA/4.webp`;
   
    
    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1,
            cols: 1,
        },
        {
            src: p3,
            source: p3,
            rows: 1,
            cols: 1,
        },
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p4,
            source: p4,
            rows: 1.5,
            cols: 2,
        },
       
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                MATH CCA 
                                </Typography>
                                <br></br>
                                <Typography variant='h7' align='justify' >
                                Class 3: Designing a Forest using Numbers.
                                <br></br>
                                Class 4: Designing a Forest using Circles and Semicircles Cut Outs.
                                <br></br>
                                Class 5: Designing a Forest using Fractional Cut Outs only from Circles. 
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 3 to 5   Date: 26, 27 and 30 OCTOBER 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                The final rounds of Math CCA, held in the fourth week of October saw an active and enthusiastic participation by the students of Classes 3-5. It explores the creative and mathematical aspects of students using geometric shapes and numbers.
                                    <br></br>
                                    Students of Class 3 displayed their activity and spoke fluently on the topic “Designing a Forest using Numbers”. They were confident and well prepared for the event thereby showcasing their constructive amount of creativity. 
                                    <br></br>
                                    Students of Class 4 displayed their activity and spoke effectively on the topic, “Designing a Forest using Circles and Semicircles”. The students applied mathematical concepts to create artistic representation of forest.
                                    <br></br>
                                    Students of Class 5 displayed their activity and spoke enthusiastically on the topic, “Designing a Forest using Fractional Parts of a Circle”. The students applied mathematical concepts to create artistic representation of forest and enhanced problem-solving skills by using fractional parts creatively.
                                    <br></br>
                                    The idea of creating a forest with numbers, shapes and fractional parts might seem unconventional, but it exemplifies the limitless potential of mathematical concepts. This event has been carefully crafted to explore the beauty and application of Mathematics beyond class room.
                                </Typography>
                            </Box>
                            {/* <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                            ><br />
                                “All the world is a laboratory to the inquiring minds!”
                                <br />
                            </Typography> */}

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 300 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <SidebarMath/>
                        </Box>
                       
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default MathCCA2023;